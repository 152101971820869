import React from 'react'
import styled from "styled-components";

import pruebas05 from "../img/messenger-icon.svg";
import pruebas06 from "../img/whatsapp-icon.svg";
import pruebas12 from "../img/telegram-icon.svg";
import pruebas07 from "../img/instagram-icon.svg";
import pruebas09 from "../img/email-icon.svg";
import pruebas11 from "../img/direct-call-icon.svg";
import pruebas13 from "../img/video-conferencing-icon.svg";

import manitoSpanish from "../img/manitoespanol.svg";
import manitoEnglish from "../img/manitoingles.svg";

const ContactSection = styled.div`

    position: fixed;
    font-family: "Roboto",sans-serif;
    background-color: transparent;
    background: #fff;
    box-shadow: 0 6px 20px 0px rgb(0 0 0 / 52%);
    z-index: 9999;
    font-size: 15px;
    top: 0%;
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
    overflow-y: auto;
    
    @media (min-width: 1024px){
        overflow-y: none;
        right: 70px;
        width: 300px;
        max-width: 370px !important;
        border-radius: 0.5rem;
    }

    @media(max-width: 1024px) {

        animation-duration: 100ms;
        animation-name: slideDownToUp;

        @keyframes slideDownToUp {
            from {
              margin-top: 2rem;
              color: #ededed !important;
            }
        
            to {
              margin-top: 0;
              color: #222 !important;
            }
        }
    }
`;


const HeaderContact = styled.div`

    background: #fff;
    text-align: center;
    width: 100%;
    color: #222;
    text-decoration: none;

    @media(max-width: 1024px){
        padding-top: 10px;
    }
`;

const ButtonClose = styled.button`

    font-size: 30px;
    position: relative;
    float: right;
    z-index: 99999;
    color: #000;
    top: 20px;
    background: #fff;
    padding: 6px 10px;
    font-weight: 300;
    margin-left: 10px;
    border: 0;
    margin-right: 1.5rem;
    
    @media (max-width: 1024px){
        top: 20px;
    }
`;

const BodyContact = styled.div`

    padding: 0px;
    display: flex;
    flex-direction: column;

    @media (max-width: 460px){
        margin-top: 50px;
    }
`;


const ItemContact = styled.a`

    
    width: 100%;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    @media (max-width: 1024px){
        width: auto;
        margin-left: 10px;
        margin-bottom: -10px;
    }
`;

const TextContainer = styled.div`

    display:flex;
    flex-direction: row;
    width: 100%;

    ${ItemContact}:hover & {
        a {
            font-weight: 700;
        }
    }
`;


const Url = styled.a`
    text-decoration: none;
    color: #000;
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
    width: 100%;
    height: 5%;

    @media (max-width: 1024px){
        font-size: 17px;
        margin-top: 8px;
    }
`;


const Description = styled.span`
    color: #969090;
`;

const Icon = styled.img`
    width: 40px;
    height: 40px;
    margin-left: 12px;
    margin-right: 10px;

    @media (max-width: 359px){
        width: 40px !important;
        width: 40px !important;
        margin-left: 0px !important;
    }
`;

const HeaderTitle = styled.h3`
    margin-top: 40px;
    font-weight: 400;
    font-size: 26px;

    @media (min-width: 1026px){
        height: 100%;
        margin-top: 0px;
        padding-top: 15px;
    }
`;

const HeaderPlus = styled.div`
    background-color: #222;
    height: 70px;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: row;
`;

const MiniCircle = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background-color: #91c508;
    margin: 0 0.2rem;
`;

const TitleHeaderPlus = styled.div`
    width: 100%;
    display: flex;
    align-items: end;
    
    img {
        width: 300px;
        height: 16.49px;
        margin: auto;
    }
`;

const PolicyContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3rem;
`;

const Policy = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PolicyTitle = styled.a`
    color: #222;
    text-decoration: underline;
    h6 {
        font-size: 10px;
    }
`;

const Lock = styled.div`
    margin-bottom: 5px;
`;

export const ContactPopUp = (props) => {

    return (
        <>
            <ContactSection style={typeof window !== 'undefined' 
                && window.localStorage.getItem("lang") == 'en' ? {maxWidth: "350px", width: "200px"} 
                : {}}>
                <HeaderPlus>
                    <TitleHeaderPlus>
                    {typeof window !== 'undefined' 
                    && window.localStorage.getItem("lang") == 'es' ? (
                        <><img src={manitoSpanish} /></>
                    ) : (<><img src={manitoEnglish}></img></>)}
                    </TitleHeaderPlus>
                </HeaderPlus>
                <HeaderContact> 
                    {typeof window !== 'undefined' 
                    && window.localStorage.getItem("lang") == 'es' ? (
                        <HeaderTitle>¡Consúltenos Ahora Mismo!</HeaderTitle>
                    ) : (<HeaderTitle>Consult Us Right Now!</HeaderTitle>)}
                    <ButtonClose onClick={props.changeOpenWidget}>X</ButtonClose>
                </HeaderContact>

                <BodyContact>
                    <ItemContact href="https://m.me/dentalvip" target="_blank">
                        <TextContainer href="https://m.me/dentalvip" target="_blank">
                            <Icon src={pruebas05}></Icon>
                            <Url href="https://m.me/dentalvip" target="_blank">Messenger</Url>
                        </TextContainer>
                    </ItemContact>

                    <ItemContact href={window.localStorage.getItem("lang") == 'es' ? "https://api.whatsapp.com/send?phone=584241392064" : "https://api.whatsapp.com/send?phone=584149033547"} target="_blank">
                        <TextContainer>
                            <Icon src={pruebas06}></Icon>
                            <Url href={window.localStorage.getItem("lang") == 'es' ? "https://api.whatsapp.com/send?phone=584241392064" : "https://api.whatsapp.com/send?phone=584149033547"} target="_blank">WhatsApp</Url>
                        </TextContainer>
                    </ItemContact>

                    <ItemContact href={window.localStorage.getItem("lang") == 'es' ? "https://t.me/dentalvipcaracas " : "https://t.me/dentalvipcaracas_en"} target="_blank">
                        <TextContainer>
                            <Icon src={pruebas12}></Icon>
                            <Url href={window.localStorage.getItem("lang") == 'es' ? "https://t.me/dentalvipcaracas " : "https://t.me/dentalvipcaracas_en"} target="_blank">Telegram</Url>
                        </TextContainer>
                    </ItemContact>

                    <ItemContact href="https://www.instagram.com/dental_vip/" target="_blank">
                        <TextContainer>
                            <Icon src={pruebas07}></Icon>
                            <Url href="https://www.instagram.com/dental_vip/" target="_blank">Instagram</Url>
                        </TextContainer>
                    </ItemContact>

                    <ItemContact href="mailto:info@dentalvipcaracas.com" target="_blank">
                        <TextContainer>
                            <Icon src={pruebas09}></Icon>
                            <Url href="mailto:info@dentalvipcaracas.com">Email</Url>
                        </TextContainer>
                    </ItemContact>

                    <ItemContact href="tel:+582122613732">
                        <TextContainer>
                            <Icon src={pruebas11}></Icon>
                            <Url href="tel:+582122613732">
                            {typeof window !== 'undefined' 
                                && window.localStorage.getItem("lang") == 'es' ? (
                        <span>Llamada Directa</span>
                    ) : (<span>Direct Call</span>)}
                            </Url>
                        </TextContainer>
                    </ItemContact>

                    <ItemContact href={typeof window !== 'undefined' 
                                && window.localStorage.getItem("lang") == 'es' ? 'https://docs.google.com/forms/d/e/1FAIpQLSfKSf-lcrRb0jrnMlgxudhvGIx6rbqAKx0M_2jQltnTJfWJEw/viewform'
                                : 'https://docs.google.com/forms/d/e/1FAIpQLSdxQDI62IcFRH1yVzQxoWeYUj0hnViT5ptZujWXtDLEI2xEnQ/viewform'}
                                target="_blank">
                        <TextContainer>
                            <Icon src={pruebas13}></Icon>
                            <Url href={typeof window !== 'undefined' 
                                && window.localStorage.getItem("lang") == 'es' ? 'https://docs.google.com/forms/d/e/1FAIpQLSfKSf-lcrRb0jrnMlgxudhvGIx6rbqAKx0M_2jQltnTJfWJEw/viewform'
                                : 'https://docs.google.com/forms/d/e/1FAIpQLSdxQDI62IcFRH1yVzQxoWeYUj0hnViT5ptZujWXtDLEI2xEnQ/viewform'}>
                            {typeof window !== 'undefined' 
                                && window.localStorage.getItem("lang") == 'es' ? (
                        <span>Videoconferencia</span>
                    ) : (<span>Videoconference</span>)}
                            </Url>
                        </TextContainer>
                    </ItemContact>

                </BodyContact>

                <PolicyContainer>
                        <Policy>
                             <Lock>
                             <span class="icon-lock"></span>
                             </Lock>
                            {typeof window !== 'undefined' 
                                 && window.localStorage.getItem("lang") == 'es' ? (
                            <PolicyTitle href='https://dentalvipcaracas.com/politica-de-privacidad/'><h6>POLÍTICA DE PRIVACIDAD</h6></PolicyTitle>
                        ) : (<PolicyTitle href='https://dentalvipcaracas.com/en/privacy-policy/'><h6>PRIVACY POLICY</h6></PolicyTitle>)}
                        </Policy>
                    </PolicyContainer>
            </ContactSection>
        </>
    )

}
