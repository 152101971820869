import React, { useState, useEffect } from "react";
import styled from "styled-components";
import chat38 from "../img/chat--38.svg";


const CircleContact = styled.div`

    background-color: #91c508;
    box-shadow: 0px 1px 8px #0000003b, 0 2px 5px #0000003b;
    opacity: ${(props) => !props.transparent ? 0 : 1};
    align-items: center;
    border: 0;
    border-radius: 50%;
    bottom: 10px;
    cursor: pointer;
    display: flex;
    font-size: 2em;
    width: 60px;
    height: 60px;
    justify-content: center;
    outline: none;
    padding: 10px;
    position: fixed;
    right: 20px;
    text-align: center;
    text-decoration: none;
    transition: all .7s;
    z-index: 2;

    @media (min-width: 768px){
        margin-right: 0.5rem !important;
        right: 40px !important;
    }

    .image-icon {
        position: relative;
        display: inline-block;
        width: 100px;
        height: auto;
        animation: incomingCall 1.5s infinite;
        z-index: 1;
        cursor: pointer;
      }
      
      .image-icon .image {
        transition: filter 0.3s ease-in-out;
        /* Transición de filtro */
      }
      
      .image-icon:hover .image {
        filter: brightness(0.8);
        /* Cambiar el ícono al gris claro */
      }
      
      @keyframes incomingCall {
      
        0%,
        100% {
            transform: rotate(0) scale(1) skew(1deg);
        }
      
        10%,
        30% {
            transform: rotate(-25deg) scale(1) skew(1deg);
        }
      
        20%,
        40% {
            transform: rotate(25deg) scale(1) skew(1deg);
        }
      
        50% {
            transform: rotate(0) scale(1) skew(1deg);
        }
      
        100% {
            transform: rotate(0) scale(1) skew(1deg);
        }
      }

`;

const CentralIcon = styled.div`

    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
`;


export const ContactWidget = (props) => {


    const [lastScrollPosition, setLastScrollPosition] = useState(0);
    const [transparentWidget, setTransparentWidget] = useState(false);

    const onScroll = () => {
        
        const currentScrollPosition = window.pageYOffset ||
                document.documentElement.scrollTop;

        if(currentScrollPosition < 0){
            return;
        }

        if(currentScrollPosition >= 150){
            setTransparentWidget(true);
        }else{
            setTransparentWidget(false);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll",onScroll);
    });

    return(
        <>
            <CircleContact transparent={transparentWidget} onClick={props.changeOpenWidget}>
                    <CentralIcon>
                        <img src={chat38} className="image-icon"></img>
                    </CentralIcon>                
                </CircleContact>
        </>
    );
};
